<template>
    <div class="user-container">
        <CRow>
            <CCol col="12">
                <CCard class="p-0">
                    <CCardHeader>
                        <div class="d-flex">
                            <div class="flex-grow-1">
                                <h5>Dashboard Summary Activity Document</h5>
                            </div>
                        </div>
                    </CCardHeader>
                    <CCardBody>
                        <CRow>
                            <CCol>
                                <div class="d-flex" style="margin-bottom:10px!important">
                                    <div class="flex-grow-1">
                                    </div>
                                    <div class="flex-grow-0 ml-10">
                                        <div class="d-inline-flex justify-center">
                                            <div>
                                                <CButton v-if="!filterChart.showFilter" @click="filterChart.showFilter = !filterChart.showFilter" class="btn-outline-danger btn-sm">
                                                    Filter<font-awesome-icon icon="filter"/>
                                                </CButton>
                                                <CButton v-else @click="filterChart.showFilter = !filterChart.showFilter" class="btn-sm">
                                                    <font-awesome-icon icon="times"/>
                                                </CButton>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <CCollapse :show="filterChart.showFilter" :duration="400">
                                    <CCard class="p-0"><CCardBody>
                                        <div class="d-flex">
                                            <div class="flex-grow-1">
                                                <h5>Filter Aktifitas</h5>
                                            </div>
                                        </div>
                                        <CRow class="filter-container">
                                            <CCol col="5">
                                                <div class="form-input">
                                                    <label class="label">Tanggal Awal</label>
                                                    <date-picker
                                                        v-model="filterChart.startDate"
                                                        :default-value="filterChart.startDate"
                                                        placeholder="YYYY-MM-DD"
                                                        value-type="format"
                                                        type="date"
                                                        class="w-100">
                                                    </date-picker>
                                                </div>
                                            </CCol>
                                            <CCol col="5">
                                                <div class="form-input">
                                                    <label class="label">Tanggal Akhir</label>
                                                    <date-picker
                                                        v-model="filterChart.endDate"
                                                        :default-value="filterChart.startDate"
                                                        placeholder="YYYY-MM-DD"
                                                        value-type="format"
                                                        type="date"
                                                        class="w-100">
                                                    </date-picker>
                                                </div>
                                            </CCol>
                                        </CRow>
                                        <div class="d-flex justify-end">
                                        <CButton @click="getChartInfo()" color="success" class="btn-sm mt-4">
                                            Terapkan
                                        </CButton>
                                        </div>
                                    </CCardBody></CCard>
                                </CCollapse>
                            </CCol>
                        </CRow>
                        <CRow>
                            <CCol col="6 text-left">
                                Surat Sudah Diaksi
                            </CCol>
                            <CCol col="6 text-right">
                                Surat Belum Diaksi
                            </CCol>
                            <CCol col="12">
                                <div class="progress">
                                    <div class="progress-bar bg-success" role="progressbar" id="progressBarDone" style="width: 50%" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100">{{percentageDone}} %</div>
                                    <div class="progress-bar bg-warning" role="progressbar" id="progressBarActive" style="width: 50%" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100">{{percentageActive}} %</div>
                                </div>
                            </CCol>
                        </CRow>
                        <CRow>
                            <CCol col="4">
                                <CRow>
                                    <CCol col="12">
                                        <h6 class="text-center mb-0">Surat Masuk</h6>
                                    </CCol>
                                </CRow>
                                <CRow>
                                    <CCol col="12">
                                        <chart-pie
                                        :datasets="dataSetsDocumentIn"
                                        :labels="pieChartLabel"
                                        ></chart-pie>

                                    </CCol>
                                </CRow>
                            </CCol>
                            <CCol col="4">
                                <CRow>
                                    <CCol col="12">
                                        <h6 class="text-center mb-0">Surat Keluar</h6>
                                    </CCol>
                                </CRow>
                                <CRow>
                                    <CCol col="12">
                                        <chart-pie
                                        :datasets="dataSetsDocumentOut"
                                        :labels="pieChartLabel"
                                        ></chart-pie>
                                    </CCol>
                                </CRow>
                            </CCol>

                            <CCol col="4">
                                <CRow>
                                    <CCol col="12">
                                        <h6 class="text-center mb-0">Surat Nota Dinas</h6>
                                    </CCol>
                                </CRow>
                                <CRow>
                                    <CCol col="12">
                                        <chart-pie
                                        :datasets="dataSetsDocumentNotaDinas"
                                        :labels="pieChartLabel"
                                        ></chart-pie>
                                    </CCol>
                                </CRow>
                            </CCol>
                        </CRow>
                    </CCardBody>
                </CCard>
            </CCol>
        </CRow>
	</div>
</template>




<script>
import 'vue-multiselect/dist/vue-multiselect.min.css'
import { mapState } from 'vuex'
import { CChartPie, CChartHorizontalBar } from '@coreui/vue-chartjs'
export default {
    name: 'Activity Chart',

    data () {
        return {
            percentageDone: 0,
            percentageActive: 0,

            filterChart: {
                showFilter: false,
                startDate: null,
                endDate: null,
            },

            pieChartLabel: ['Surat Sudah Diaksi', 'Surat Belum Diaksi'],
			pieChartColorLabel: ['#6aa84f', '#FFBF00'],
			dataSetsDocumentIn: [],
			dataSetsDocumentOut: [],
			dataSetsDocumentNotaDinas: [],

			pieChartDataDocumentIn: {
				data: [],
				backgroundColor: []
			},
			pieChartDataDocumentOut: {
				data: [],
				backgroundColor: []
			},
			pieChartDataDocumentNotaDinas: {
				data: [],
				backgroundColor: []
			}
        }
    },

    mounted () {
        this.getChartInfo()
    },

    methods: {
        getChartInfo() {
			this.$store.dispatch({
				type: 'summary/getDocSummary',
				filter: this.filterChart
			}).then( res => {
				var result = res.result

				if(result.surat_masuk != undefined) {
					this.dataSetsDocumentIn = []
					this.pieChartDataDocumentIn.data = []
					this.pieChartDataDocumentIn.data.push(result.surat_masuk.surat_selesai)
					this.pieChartDataDocumentIn.data.push(result.surat_masuk.surat_aktif)
					this.pieChartDataDocumentIn.backgroundColor = this.pieChartColorLabel
					this.dataSetsDocumentIn.push(this.pieChartDataDocumentIn)
				}


				if(result.surat_keluar != undefined) {
					this.dataSetsDocumentOut = []
					this.pieChartDataDocumentOut.data = []
					this.pieChartDataDocumentOut.data.push(result.surat_keluar.surat_selesai)
					this.pieChartDataDocumentOut.data.push(result.surat_keluar.surat_aktif)
					this.pieChartDataDocumentOut.backgroundColor = this.pieChartColorLabel
					this.dataSetsDocumentOut.push(this.pieChartDataDocumentOut)
				}


				if(result.surat_nodin != undefined) {
					this.dataSetsDocumentNotaDinas = []
					this.pieChartDataDocumentNotaDinas.data = []
					this.pieChartDataDocumentNotaDinas.data.push(result.surat_nodin.surat_selesai)
					this.pieChartDataDocumentNotaDinas.data.push(result.surat_nodin.surat_aktif)
					this.pieChartDataDocumentNotaDinas.backgroundColor = this.pieChartColorLabel
					this.dataSetsDocumentNotaDinas.push(this.pieChartDataDocumentNotaDinas)
				}

				if(result.semua_surat != undefined) {
					this.percentageDone = Math.round((result.semua_surat.surat_selesai / result.semua_surat.total_surat) * 100)
					this.percentageActive = 100 - this.percentageDone

					document.getElementById("progressBarDone").style.width = this.percentageDone+"%"
					document.getElementById("progressBarActive").style.width = this.percentageActive+"%"
				}

			}).catch( err => {
			})
		}
    },

    components: {
        'chart-pie': CChartPie
    }
}



</script>

<style scoped>

</style>

